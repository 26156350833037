import CustomFieldsService from "../services/CustomFieldsService";

class customFieldsStore {
    async sendData(data) {
        try {
            const res = CustomFieldsService.saveData(data);
            return res;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }

    async getData() {
        try {
            const res = CustomFieldsService.getData();
            return res;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }
}

export default new customFieldsStore();