import axios from "axios";
import $api from "../http";

export default class PostService {
    static async getCategories(parent:any) {
        return $api.get('/admin/category/'+parent);
    }

    static async createCategoryItem(title:string, image: any, isPost: boolean, content: string, parent: string, description:string, restrictStr:string, isRescrict: boolean) {
        return $api.post('/admin/category/create', {title, image, isPost, content, parent, description, restrictStr, isRescrict})
    }    

    static async getCategoryItem(id:string) {
        return $api.get(`/admin/category/${id}/info`)
    }

    static async updateCategoryItem(id:string, postTitle:string, postImage:string, postType:boolean, postLink:string, postParent:string, description:string, restrictStr:string, isRescrict: boolean) {
        return $api.put(`/admin/category/${id}/edit`, {postTitle, postImage, postType, postLink, postParent, description, restrictStr, isRescrict})
    }

    static async removeItem(id:string) {
        return $api.delete(`/admin/category/${id}`)
    }

    static uploadIcon(file: any) {
        const data = new FormData() ;
        data.append('icon', file);
        return $api.post("/admin/upload/image", data, {
            headers: {
                'Content-Type': file.type
            }
        });

    }
}