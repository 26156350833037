import { observer } from "mobx-react-lite";
import { InputText } from "primereact/inputtext";
import { FC, useRef, useState, FormEvent, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import PostStore from "../../store/postStore";
import { Toast } from "primereact/toast";
import { InputTextarea } from 'primereact/inputtextarea';
import config from "../../config";
// import EditorContainer from "../EditorComponent";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Checkbox } from "primereact/checkbox";

const PostEdit = (props: any,) => {

    const toast = useRef<any>();
    const postStore = new PostStore();

    const { type, postId, parent, onCreate } = props;
    const [isEdit, setEdit] = useState(postId?true:false)
    const [postTitle, setPostTitle] = useState('');
    const [postType, setPostType] = useState(false);
    const [postLink, setPostLink] = useState('');
    const [postImage, setPostImage] = useState('');
    const [postAccess, setPostAccess] = useState(false);
    const [postParent, setPostParent] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionEditor, setDescriptionEditor] = useState<EditorState>();
    const [types, setTypes] = useState<any>([]);
    const [selectedTypes, setSelectedTypes] = useState<any>([]);

    
    useEffect(() => {
        if (type == 'edit' && postId && postId !== -1 ) {
            getPostData(postId);
        } else {
            getPostTypes();
        }
    }, [])
    

    const showWarn = (msg:any, severity:any = 'error', summary:any = 'Ошибка') => {
        toast.current.show({ severity: severity, summary: summary, detail: msg, life: 3000 });
    };

    const showMsg = (text: any) => {
        showWarn(text, 'success', 'Успешно')
    }

    const showErrorMsg = (result:any) => {
        showWarn(result, 'error')
    }

    const getPostTypes = async () => {
        const {types} = await postStore.getCategoryItem('-1');
        setTypes(types);
    }


    const getPostData = async (id:string) => {
        const res = await postStore.getCategoryItem(id);

        const {title, isPost, image, parent, description, content, types, restrictStr, isRescricted} = res;
        
        setPostTitle(title);
        setPostType(isPost==1?true:false);
        setPostImage(image);
        setPostParent(parent)
        setDescription(description);
        setDescriptionEditor(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(description).contentBlocks
                )
            )
        );
        setPostLink(content)
        setPostImage(image)

        setTypes(types);
        setSelectedTypes(restrictStr.split(','));
        setPostAccess(isRescricted==0?false:true);
    }

    const submitPostForm = (e:FormEvent) => {
        e.preventDefault();

        if (postId) {
            saveItem(postId);
        } else {
            createItem();
        }
    }

    const saveItem = async (id:string) => {

        const res = await postStore.updateCategoryItem(id, postTitle, postImage, postType, postLink, postParent, description, selectedTypes.join(','), postAccess)

        if (res == id) {
            showMsg('Элемент успешно изменён!');
        } else {
            showErrorMsg(res);
        }
    }

    const createItem = async () => {
        const res = await postStore.createCategoryItem(postTitle, postImage, postType, postLink, parent, description, selectedTypes.join(','), postAccess);
        
        console.log(res);
        if (res.message) {
            showErrorMsg(res.message);
        } else {
            showMsg('Элемент создан!');
            onCreate();
        }
    }

    const onCheckboxChange = (e:any) => {
        let selectedValue = [...selectedTypes];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setSelectedTypes(selectedValue);
    };


    return (
        // <div className="grid">

            <div className="">
                <Toast ref={toast} />

                <form onSubmit={(e) => submitPostForm(e)} className="p-fluid">
                    {/* <h5>{isEdit?"Редактирование элемента":"Создание элемента"}</h5> */}
                    <div className="field">
                        <label htmlFor="name"><b>Название</b></label>
                        <InputText id="name" type="text" value={postTitle} onChange={(e:any) => setPostTitle(e.target.value)} /> 
                    </div>

                    <div className="field">
                        <label><b>Точка выхода?</b></label> <br />
                        <InputSwitch checked={postType} onChange={(e) => setPostType(e.value)} />
                    </div>

                    { postType === true &&
                        <div className="postData">
                            <div className="field">
                                <label htmlFor="post_link"><b>Содержимое</b></label>
                                <p>
                                    &#x3C;b&#x3E;Жирно&#x3C;/b&#x3E;<br />
                                    &#x3C;i&#x3E;Курсив&#x3C;/i&#x3E;<br />
                                    &#x3C;u&#x3E;Подчёркивание&#x3C;/u&#x3E;<br />
                                    &#x3C;s&#x3E;Зачёркнутый&#x3C;/s&#x3E;<br />
                                </p>
                                <InputTextarea onChange={(e) => {setPostLink(e.target.value)}} autoResize rows={15} value={postLink}></InputTextarea>
                            </div>
                        </div>
                    }

                    <div className="field">
                        <label><b>Ограничение доступа</b></label> <br />
                        <InputSwitch checked={postAccess} onChange={(e) => setPostAccess(e.value)} />
                    </div>

                    { postAccess === true &&
                        <div className="postData">
                            <div className="field">
                                {/* <label htmlFor="post_link"><b>Направления</b></label> */}
                                <div className="">
                                    {types.length>0?types.map((item) => {
                                        return (
                                            <div className="field-checkbox" key={item.calculated_bu}>
                                                <Checkbox inputId={`checkOption${item.calculated_bu}`} name="option" value={item.calculated_bu} checked={selectedTypes.indexOf(item.calculated_bu) !== -1} onChange={onCheckboxChange} />
                                                <label htmlFor={`checkOption${item.calculated_bu}`}>{item.calculated_bu}</label>
                                            </div>
                                        )
                                    }):<></>}
                                </div>
                            </div>
                        </div>
                    }

                    {/* { postType === false && 
                        <div className="categoryData">
                            <div className="editor">
                                <Editor
                                    editorState={descriptionEditor}
                                    onEditorStateChange={(state) => {
                                        setDescriptionEditor(state);
                                    }}
                                    onChange={(e) => {
                                        const data = descriptionEditor?.getCurrentContent();
                                        if (data) {
                                            const html = stateToHTML(data)
                                            setDescription(html);
                                        }
                                    }}
                                    toolbar={{
                                        options: ['inline', 'list', 'history' ],
                                        inline: { inDropdown: true },
                                    }}
                                />
                            </div>
                        
                        </div>
                    } */}
                    
                     
                    <div className="post__save-btn">
                        <Button label="Сохранить" className="p-button-raised p-button-success mr-2 mb-2"></Button>
                    </div>
                </form>
            </div>
        // </div>
    )
}

export default observer(PostEdit);