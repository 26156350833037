import PostService from "../services/PostService"

export interface Category {
    id: number;
    parent: number;
    title: string;
    image: string;
    childs: number;
    isPost: boolean;
}

export default class postStore {
    async getCategories(parent:any) {
        try {
            const res = await PostService.getCategories(parent)
            return res.data;
        } catch (e:any) {
            return e?.response.data.message
        }
    }

    async createCategoryItem(title:string, image: any, isPost: boolean, content: string, parent:string, description: string, restrictStr:string, isRescrict: boolean) {
        try {
            const res = await PostService.createCategoryItem(title, image, isPost, content, parent, description, restrictStr, isRescrict);
            return res.data
        } catch (e:any) {
            return e?.response.data.message
        }
    }
    
    async getCategoryItem(id:string) {
        try {
            const res = await PostService.getCategoryItem(id);

            return res.data;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }

    async updateCategoryItem(id:string, postTitle:string, postImage:string, postType:boolean, postLink:string, postParent:string, description:string, restrictStr:string, isRescrict: boolean) {
        try {
            const res = await PostService.updateCategoryItem(id, postTitle, postImage, postType, postLink, postParent, description, restrictStr, isRescrict);

            return res.data;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }

    async removeItem(id: string) {
        try {
            const res = await PostService.removeItem(id);
            return res.data;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }

    async uploadIcon(file: any) {
        try {
            const res = await PostService.uploadIcon(file);
            return res.data;
        } catch (e:any) {
            return e?.response.data.message;
        }
    }
}