import React, { useContext }  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Context } from '..';

export const AppTopbar = (props:any) => {
    const { store } = useContext(Context);


    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                {/* <img src={props.layoutColorMode === 'light' ? '/assets/layout/images/logo-dark.svg' : '/assets/layout/images/logo-white.svg'} alt="logo"/> */}
                <span>Sanofi MP</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li className='topbar-name'>
                    <div></div>
                    {store.user.name}
                </li>
                <li>
                    <Link to="/settings" className="p-link layout-topbar-button">
                        <i className="pi pi-user-edit pi-icons-big" />
                        <span>Настройки</span>
                    </Link>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.logout}>
                        <i className="pi pi-sign-out pi-icons-big" />
                        <span>Выйти</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
