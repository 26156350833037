import { observer } from "mobx-react-lite"
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea"
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react"
import customFieldsStore from "../store/customFieldsStore";

const CustomFieldsList = () => {
    const [botFirstMessage, setBotFirstMessage] = useState('');
    const [botSuccessAuth, setBotSuccessAuth] = useState('');
    const [botErrorAuth, setBotErrorAuth] = useState('');
    const [botErrorMsg, setBotErrorMsg] = useState('');

    const [htmlErrorAuth, setHtmlErrorAuth] = useState('');
    const [htmlIndexText, setHtmlIndexText] = useState('');

    const toast = useRef<any>();

    const showWarn = (msg:any) => {
        toast?.current.show({ severity: 'error', summary: 'Ошибка', detail: msg, life: 3000 });
    };

    const showSuccess = (msg:any) => {
        toast?.current.show({ severity: 'success', summary: 'Сохранено!', detail: msg, life: 3000 });
    };

    const saveData = async () => {
        const res = await customFieldsStore.sendData({
            botFirstMessage,
            botSuccessAuth,
            botErrorAuth,
            htmlErrorAuth,
            htmlIndexText,
            botErrorMsg,
        });

        if (res) {
            showSuccess('');
        } else {
            showWarn('');
        }
    }

    const getData = async () => {
        const res = (await customFieldsStore.getData()).data;

        if (res && res.length > 0) {
            res.map(
                (item: {id:number, meta_key:string, meta_value:string}) => {
                switch (item.meta_key) {
                    case "botFirstMessage": setBotFirstMessage(item.meta_value); break;
                    case "botSuccessAuth": setBotSuccessAuth(item.meta_value); break;
                    case "botErrorAuth": setBotErrorAuth(item.meta_value); break;
                    case "htmlErrorAuth": setHtmlErrorAuth(item.meta_value); break;
                    case "htmlIndexText": setHtmlIndexText(item.meta_value); break;
                    case "botErrorMsg": setBotErrorMsg(item.meta_value); break;
                }
            })
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Button label="Сохранить" className="p-button-success mr-2 mb-2" onClick={saveData} />
                    <h3>Сообщения от бота</h3>
                    <h6>
                        <p>
                            &#x3C;b&#x3E;Жирно&#x3C;/b&#x3E;<br />
                            &#x3C;i&#x3E;Курсив&#x3C;/i&#x3E;<br />
                            &#x3C;u&#x3E;Подчёркивание&#x3C;/u&#x3E;<br />
                            &#x3C;s&#x3E;Зачёркнутый&#x3C;/s&#x3E;<br />
                        </p>
                    </h6>
                    <div>
                        <h5>Приветственный текст (после нажатия кнопки "запустить")</h5>
                        <InputTextarea 
                            placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botFirstMessage} onChange={(e) => {setBotFirstMessage(e.target.value)}} />
                    </div><br />
                    <div>
                        <h5>Успешная авторизация</h5>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botSuccessAuth} onChange={(e) => {setBotSuccessAuth(e.target.value)}} />
                    </div><br />
                    <div>
                        <h5>Номер отсутствует в базе</h5>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botErrorAuth} onChange={(e) => {setBotErrorAuth(e.target.value)}} />
                    </div><br />
                    <div>
                        <h5>Ошибочное сообщение боту</h5>
                        <InputTextarea placeholder="Текст.." autoResize rows={3} className="customfieds__textarea" value={botErrorMsg} onChange={(e) => {setBotErrorMsg(e.target.value)}} />
                    </div><br />

                    <Button label="Сохранить" className="p-button-success mr-2 mb-2" onClick={saveData} />
                </div>
            </div>

            <Toast ref={toast} />
        </div>
    )
}

export default observer(CustomFieldsList)