import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import medStore, { MedListInterface } from "../../store/medStore";
import { useParams } from "react-router-dom";
import Paginator from "../Paginator";



const MedList:FC = () => {
    const { page } = useParams();
    const MedStore = new medStore();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [displayLoadDialog, setDisplayLoadDialog] = useState(false);
    const [medFile, setMedFile] = useState<any>(undefined)
    const [medList, setMedList] = useState<MedListInterface[]>()
    const toast = useRef<any>();
    let file = null;

    const [lastPage, setLastPage] = useState('');

    const getMeds = async () => {
        await MedStore.getMedList(page?page:0).then((res) => {
            setMedList(res.meds)
            setLastPage(res.pageCount);
        })
    }

    useEffect(() => {
        getMeds();
    }, [page])

    const showWarn = (msg:any, severity:any = 'error', summary:any = 'Ошибка', life:number = 3000)  => {
        toast.current.show({ severity: severity, summary: summary, detail: msg, life: life });
    };

    const confirmUploadFooter = () => {
        const onConfirm = () => {
            showWarn('Загрузка файла..', 'info', "Ожидание")
            // console.log(medFile[0])
            MedStore.uploadMeds(medFile[0]).then((result) => {
                console.log(result);
                if (result.data.errors) {
                        showWarn(result.data.errors.join('\n'), 'error', 'Ошибка', 10000);
                } else {
                    showWarn('Файл загружен', 'success', "Успешно");
                    getMeds();
                }
            
                setDisplayConfirmation(false)
            })
            
        }

        return (
            <>
                <Button type="button" label="Нет" icon="pi pi-times" onClick={() => {file = null; setMedFile(null); setDisplayConfirmation(false)}} className="p-button-text" autoFocus />
                <Button type="button" label="Да" icon="pi pi-check" onClick={onConfirm} className="p-button-text"  />
            </>
        )
    };

    const loadDialogFooter = () => {
        const onCLick = () => {
            if (file == null) {
                showWarn('Файл не выбран')
                return false;
            }

            setMedFile(file);
            setDisplayConfirmation(true)
            setDisplayLoadDialog(false);
        }

        return (
            <>
                <Button type="button" label="Отмена" icon="pi pi-times" onClick={() => {file = null; setMedFile(null); setDisplayLoadDialog(false)}} className="p-button-text" autoFocus />
                <Button type="button" label="Продолжить" icon="pi pi-check" onClick={onCLick} className="p-button-text" />
            </>
        )
    };

    const UploadDialog = () => {

        return (
            <>
            <Dialog header="Загрузка базы Медпредов" visible={displayLoadDialog} onHide={() => {setDisplayLoadDialog(false) }} style={{ width: '350px' }} modal footer={loadDialogFooter} className="uploadDialog">
                <div className="field">
                <label htmlFor="medfile"><b>Выбрать файл</b></label>
                <input type="file" id="medfile" name="medfile" accept=".csv" onChange={async (e:any) => {
                    e.preventDefault();
                    file = e.target.files
                }}/>
                </div>
            </Dialog>

            <Dialog header="Подтверждение" visible={displayConfirmation} onHide={() => {setDisplayConfirmation(false) }} style={{ width: '350px' }} modal footer={confirmUploadFooter}  className="uploadDialog">
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>После загрузки данных старые будут удалены, вы уверены, что хотите продолжить? </span>
                </div>
            </Dialog>
            </>
        )
    }

    return (
        <>
        <Toast ref={toast} />
        <UploadDialog />

        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div>
                        <h5>Медпреды</h5>
                        <Button label="Загрузить Медпредов" className="p-button p-component p-button-outlined mr-2 mb-2" onClick={ () => {
                            setDisplayLoadDialog(true);
                            }} />

                        <div className="p-datatable p-component p-datatable-responsive-scroll p-datatable-gridlines p-datatable-gridlines">
                            <table className="p-datatable-table" role="table">
                                <thead className="p-datatable-thead">
                                    <tr role="row">
                                        <th className="" role="columnheader">
                                            <div className="p-column-header-content">
                                                <span className="p-column-title">EmployeeNumber</span>
                                            </div>
                                        </th>
                                        <th className="" role="columnheader">
                                            <div className="p-column-header-content">
                                                <span className="p-column-title">name</span>
                                            </div>
                                        </th>
                                        <th className="" role="columnheader">
                                            <div className="p-column-header-content">
                                                <span className="p-column-title">calculated_bu</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="p-datatable-tbody">
                                    {medList?.map((item:MedListInterface) => {
                                        return (
                                            <tr role="row" key={item.id}>
                                                <td role="cell">{item.employeenumber}</td>
                                                <td role="cell">{item.name}</td>
                                                <td role="cell">{item.calculated_bu}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            
                            { lastPage != '0' && 
                                <Paginator curPage={page?page:'0'} lastPage={lastPage} url="/meds" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default observer(MedList);