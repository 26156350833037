import $api from "../http";

export default class CustomFieldsService {
    static async saveData(data: any) {
        return $api.post('/admin/fields/save', {data: data});
    }

    static async getData() {
        return $api.get('/admin/fields');
    }
}