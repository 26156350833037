import { useEffect, FC, useContext, useState, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginForm from './components/LoginForm';
import { Context } from '.';
import { observer } from 'mobx-react-lite';
import { IUser } from './models/IUser';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import classNames from 'classnames';
import { AppTopbar } from './components/AppTopbar';
import { AppMenu } from './components/AppMenu';
import UserSettings from './components/users/UserSettings';
import UserList from './components/users/UserList';
import UserCreate from './components/users/UserCreate';
import PostHome from './components/post/PostHome';
import MedList from './components/meds/MedList';
import StatList from './components/statistics/statList';
import PostEdit from './components/post/PostEdit';
import MessageLog from './components/statistics/MessageLog';
import CustomFieldsList from './components/CustomFieldsList';

const App: FC = () => {
  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  PrimeReact.ripple = true;

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    // 'layout-theme-light': layoutColorMode === 'light'
  });

  const admin_menu = [
    {
        items: [
          {label: 'Контент', icon: 'pi pi-fw pi-home', to: '/'},
          {label: 'Пользователи', icon: 'pi pi-fw pi-users', to: '/users'},
          {label: 'Медпреды', icon: 'pi pi-fw pi-check-circle', to: '/meds'},
          {label: 'Статистика пользователей', icon: 'pi pi-fw pi-percentage', to: '/stats2'},
          {label: 'Статистика действий пользователей ', icon: 'pi pi-fw pi-percentage', to: '/stats'},
          // {label: 'Нежелательные явления', icon: 'pi pi-fw pi-book', to: '/log/messages'},

          {label: 'Редактирование текстов', icon: 'pi pi-fw pi-table', to: '/customfields'},
        ]
    }
  ,];

  const editor_menu = [
    {
        items: [
          {label: 'Контент', icon: 'pi pi-fw pi-home', to: '/'},
        ]
    }
  ,];

  const onWrapperClick = (e: any) => {
    if (!menuClick) {
        setOverlayMenuActive(false);
        setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
        setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  }


  const onToggleMenuClick = (event: any) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      }
      else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    }
    else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  }

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const onMobileTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
}

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }
  const isDesktop = () => {
    return window.innerWidth >= 992;
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  useEffect(() => {
    document.documentElement.style.fontSize = 14 + 'px';
  }, [])

   // End Настройки UI-kit

  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth();
    }

  }, [])

  if (store.isLoading && localStorage.getItem('token')) {
    return (<></>)
  }


  if (!store.isAuth) {
    store.setLoading(false);
    
    return (
      <div className={wrapperClass}>
        <LoginForm />
      </div>
    )
  }

  if (store.isLoading) {
    return (
      <div className={wrapperClass} onClick={onWrapperClick}></div>
    )
  }

  const AdminRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<PostHome />}/>

        <Route path="/users" element={<UserList />} />
        <Route path="/users/new" element={<UserCreate />} />
        <Route path="/users/:id" element={<UserCreate />} />

        <Route path="/categories" element={<PostHome />} />

        <Route path="/meds" element={<MedList />} />
        <Route path="/meds/:page" element={<MedList />} />

        <Route path="/stats" element={<StatList type="1" />} />
        <Route path="/stats/:page" element={<StatList type="1" />} />

        <Route path="/stats2" element={<StatList type="2" />} />
        <Route path="/stats2/:page" element={<StatList type="2" />} />
        {/* <Route path="/log/messages" element={<MessageLog />} />
        <Route path="/log/messages/:page" element={<MessageLog />} /> */}
        <Route path="/settings" element={<UserSettings />} />

        <Route path="/customfields" element={<CustomFieldsList />} />
      </Routes>
    )
  }

  const EditorRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<PostHome />}/>
{/* 
        <Route path="/users" element={<UserList />} />
        <Route path="/users/new" element={<UserCreate />} />
        <Route path="/users/:id" element={<UserCreate />} />

        <Route path="/categories" element={<PostHome />} />

        <Route path="/meds" element={<MedList />} />
        <Route path="/stats" element={<StatList type="1" />} />
        <Route path="/stats2" element={<StatList type="2" />} />
        <Route path="/log/messages" element={<MessageLog />} /> */}
        <Route path="/settings" element={<UserSettings />} />
      </Routes>
    )
  }

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>

      <AppTopbar 
        logout={() => store.logout()}
        onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} /> 

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={store.user.role == 'admin'?admin_menu:editor_menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>

      <div className="layout-main-container">
          <div className="layout-main">
              { store.user.role == 'admin' &&
                <AdminRoutes /> }

              { store.user.role == 'editor' &&
                <EditorRoutes /> }
          </div>
      </div>
    </div>  
  );
}

export default observer(App);
